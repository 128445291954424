<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12" v-if="whitelabelLoading">
          <Loading />
        </v-col>

        <v-col md="12" v-if="!whitelabelLoading">
          <h3>Add New White Label</h3>
          <v-form>
            <!-- Name Field -->
            <v-label>Name</v-label>
            <v-text-field v-model="name" placeholder="Name" outlined />

            <!-- Logo Field -->
            <v-label>Logo</v-label>
            <div>
              <img :src="logoUrl" alt="Logo" width="200" v-if="logoUrl" @click="logoUrl = undefined" />
            </div>
            <v-file-input v-model="logo" placeholder="Logo" outlined />

            <!-- Fav Icon Field -->
            <v-label>Fav Icon</v-label>
            <div>
              <img :src="favIconUrl" alt="Fav Icon" width="200" v-if="favIconUrl" @click="favIconUrl = undefined" />
            </div>
            <v-file-input v-model="favIcon" placeholder="Fav Icon" outlined />

            <!-- Primary Color -->
            <v-label>Primary Color</v-label>
            <v-text-field v-model="color1" placeholder="Primary Color" outlined />

            <!-- Secondary Color -->
            <v-label>Secondary Color</v-label>
            <v-text-field v-model="color2" placeholder="Secondary Color" outlined />

            <!-- Menu Text Color -->
            <v-label>Menu Text Color</v-label>
            <v-text-field v-model="txtColor" placeholder="Menu Text Color" outlined />

            <!-- Website -->
            <v-label>Website</v-label>
            <v-text-field v-model="website" placeholder="Website" outlined />

            <!-- Sub Website -->
            <v-label>Sub Website</v-label>
            <v-text-field v-model="subwebsite" placeholder="Sub Website" outlined />

            <!-- Email -->
            <v-label>Email</v-label>
            <v-text-field v-model="email" placeholder="Email" outlined />

            <!-- Hybrid and QR Code Checkboxes -->
            <v-row>
              <v-col cols="1">
                <v-checkbox
                  v-model="hybrid"
                  label="Hybrid"
                  :true-value="true"
                  :false-value="false"
                />
              </v-col>
              <v-col cols="2">
                <v-checkbox
                  v-model="hideQR"
                  label="Hide QR Code"
                  :true-value="true"
                  :false-value="false"
                />
              </v-col>
            </v-row>

            <!-- Product Cards Checkboxes -->
            <v-row>
              <v-label>Product Cards</v-label>
            </v-row>
            <v-row>
              <v-col cols="3" v-for="(label, key) in additionalCheckboxes" :key="key">
                <v-checkbox
                  v-model="formData[key]"
                  :label="`Hide ${label}`"
                  outlined
                  :true-value="true"
                  :false-value="false"
                />
              </v-col>
            </v-row>

            <!-- Google Analytics -->
            <v-label>Google Analytics</v-label>
            <v-text-field v-model="googleAnalytics" placeholder="Google Analytics Code" outlined />

            <!-- Submission Buttons -->
            <v-btn @click="validate" :disabled="loading">Submit</v-btn>
            <div>
              <v-btn @click="SendToDeployer" :disabled="loading">1. Send to Deployer</v-btn>
              <v-btn @click="deploySiteToS3" :disabled="loading">2. Deploy Site to S3</v-btn>
              <v-btn @click="createCFDistribution" :disabled="loading">3. Create CF Distro</v-btn>
              <v-btn @click="createSSLCertificate" :disabled="loading">4. Create SSL Cert</v-btn>
              <v-btn @click="checkSSLIsReady" :disabled="loading">5. Check SSL Cert</v-btn>
              <v-btn @click="deployCFDistribution" :disabled="loading">6. Deploy SSL to CF</v-btn>
            </div>
            <v-btn loading v-if="loading"></v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar">
      {{ text }}
    </v-snackbar>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/dashboard/Loading";

const DEPLOYER_URL = "http://localhost:3000/domain";
const DEPLOY_TO_S3_URL = app_id => `http://localhost:3000/domain/${app_id}/build-to-s3`;
const CREATE_DISTRO_URL = app_id => `http://localhost:3000/domain/${app_id}/cloudfront`;
const CREATE_SSL_URL = app_id => `http://localhost:3000/domain/${app_id}/ssl-cert-request`;
const UPDATE_SSL_URL = app_id => `http://localhost:3000/domain/${app_id}/ssl-cert-request`;

const extractDomain = value => /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/igm.exec(value)[1];

export default {
  name: "AddWhiteLabel",

  components: {
    Loading,
  },

  data() {
    return {
      snackbar: false,
      text: "",
      name: "",
      color1: "",
      color2: "",
      txtColor: "",
      logo: null,
      loading: false,
      website: "",
      subwebsite: "",
      favIcon: null,
      whitelabelLoading: true,
      favIconUrl: "",
      logoUrl: "",
      hybrid: false,
      userData: null,
      email: "",
      googleAnalytics: "",
      appId: "",
      hideQR: true, // Initialized to Boolean true
      formData: {
        hideDealSourcing: true,
        hideConveyancing: true,
        hideMortgageBroker: true,
        hidePropertyManagement: true,
        hidePropertyRefurbishment: true,
        hideSellProperty: true,
        hideStampDutyReclaim: true,
        hideStartCompany: true,
      },
      additionalCheckboxes: {
        hideDealSourcing: 'Deal Sourcing',
        hideConveyancing: 'Conveyancing',
        hideMortgageBroker: 'Mortgage Broker',
        hidePropertyManagement: 'Property Management',
        hidePropertyRefurbishment: 'Property Refurbishment',
        hideSellProperty: 'Sell Property',
        hideStampDutyReclaim: 'Stamp Duty Reclaim',
        hideStartCompany: 'Start Company',
      },
    };
  },

  methods: {
    ...mapActions(["postWhiteLabel", "getWhiteLabel"]),
    async validate() {
      this.loading = true;
      const formData = new FormData();

      // Append text and file fields
      if (this.name) formData.append("name", this.name);
      if (this.color1) formData.append("color1", this.color1);
      if (this.color2) formData.append("color2", this.color2);
      if (this.logo) formData.append("logo", this.logo);
      if (this.website) formData.append("website", this.website);
      if (this.subwebsite) formData.append("subwebsite", this.subwebsite);
      if (this.favIcon) formData.append("favIcon", this.favIcon);
      if (this.email) formData.append("email", this.email);
      if (this.txtColor) formData.append("txtColor", this.txtColor);
      if (this.googleAnalytics) formData.append("googleAnalytics", this.googleAnalytics);
      if (this.$route.params.id) formData.append("_id", this.$route.params.id);

      // Append Boolean fields as strings
      formData.append("hideQR", this.hideQR.toString());
      formData.append("hybrid", this.hybrid.toString());

      // Append Product Cards Boolean fields as strings
      Object.keys(this.formData).forEach(key => {
        formData.append(key, this.formData[key].toString());
      });

      try {
        await this.postWhiteLabel(formData);
        this.snackbar = true;
        this.text = this.allWhiteLabel.message;
      } catch (error) {
        console.error(error);
        this.text = "An error occurred while submitting the form.";
        this.snackbar = true;
      } finally {
        this.loading = false;
      }
    },
    async SendToDeployer() {
      const config = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          whitelabel_id: this.appId,
          url: extractDomain(this.website),
        })
      }

      try {
        const response = await fetch(DEPLOYER_URL, config);
        const result = await response.json();
        alert(`Saved! id: ${result.id}`);
      } catch (e) {
        console.error(e);
        alert(`An Error occurred, see console:\nMessage: ${e?.message}`, e?.message)
      }
    },
    async deploySiteToS3() {
      const { appId } = this;
      const config = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({})
      }

      try {
        const response = await fetch(DEPLOY_TO_S3_URL(appId), config);
        const result = await response.json();
        alert(`Done: ${result === 'true' ? 'Success' : 'Failure'}`);
      } catch (e) {
        console.error(e);
        alert(`An Error occurred, see console:\nMessage: ${e?.message}`, e?.message)
      }
    },
    async createCFDistribution() { 
      const { appId } = this;
      const config = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({})
      }

      try {
        const response = await fetch(CREATE_DISTRO_URL(appId), config);
        const result = await response.json();
        alert(`Done:
        domain_name = ${result.domain_name}
        status = ${result.status}
        aws_link = ${result.location}
        `);
      } catch (e) {
        console.error(e);
        alert(`An Error occurred, see console:\nMessage: ${e?.message}`, e?.message)
      }
    },
    async createSSLCertificate() {
      const { appId } = this;
      const config = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({})
      }

      try {
        const response = await fetch(CREATE_SSL_URL(appId), config);
        const result = await response.json();
        alert(`Done: 
        cname_name: ${result.cname_name}
        cname_value: ${result.cname_value}
        status: ${result.status}
        `);
      } catch (e) {
        console.error(e);
        alert(`An Error occurred, see console:\nMessage: ${e?.message}`, e?.message)
      }
    },
    async checkSSLIsReady() { 
      const { appId } = this;
      const config = {
        method: 'PATCH',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({})
      }

      try {
        const response = await fetch(UPDATE_SSL_URL(appId), config);
        const result = await response.json();
        alert(`Done: 
        cname_name: ${result.cname_name}
        cname_value: ${result.cname_value}
        status: ${result.status}
        `);
      } catch (e) {
        console.error(e);
        alert(`An Error occurred, see console:\nMessage: ${e?.message}`, e?.message)
      }      
    },
    async deployCFDistribution() { 
      const { appId } = this;
      const config = {
        method: 'PATCH',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({})
      }

      try {
        const response = await fetch(CREATE_DISTRO_URL(appId), config);
        const result = await response.json();
        const { Status } = result;
        alert(`Done: 
        status: ${Status}`);
      } catch (e) {
        console.error(e);
        alert(`An Error occurred, see console:\nMessage: ${e?.message}`, e?.message)
      }
    },
  },
  computed: {
    ...mapGetters(["allWhiteLabel"]),
  },
  async created() {
    if (this.$route.params.id) {
      await this.getWhiteLabel(this.$route.params.id);
      const data = this.allWhiteLabel.results;

      this.name = data.name || "";
      this.color1 = data.color1 || "";
      this.color2 = data.color2 || "";
      this.txtColor = data.txtColor || "";
      this.logoUrl = data.logo || "";
      this.website = data.website || "";
      this.subwebsite = data.subwebsite || "";
      this.favIconUrl = data.favIcon || "";
      this.hybrid = data.hybrid || false;
      this.googleAnalytics = data.googleAnalytics || "";
      this.email = data.email || "";
      this.appId = data.appId || "";

      // Convert hideQR to Boolean if it's a string
      if (typeof data.hideQR === 'string') {
        this.hideQR = data.hideQR.toLowerCase() === 'true';
      } else {
        this.hideQR = !!data.hideQR;
      }

      // Assign values for additional checkboxes
      Object.keys(this.formData).forEach(key => {
        if (typeof data[key] === 'string') {
          this.formData[key] = data[key].toLowerCase() === 'true';
        } else {
          this.formData[key] = !!data[key];
        }
      });
    }

    this.whitelabelLoading = false;

    const token = localStorage.getItem("token");
    if (!token) {
      this.$router.push("/");
    } else {
      this.userData = JSON.parse(token).user;
    }
  },
};
</script>

<style lang="scss" scoped>
/* Your existing styles */
</style>


<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.dateHighlight {
  background: yellow;
}

.no-pad-tb {
  padding-top: 0;
  padding-bottom: 0;
}

h4 {
  margin-bottom: 0;
}

.v-icon {
  font-size: 16px;
  cursor: pointer;
}

.downloadbtn {
  margin-left: 20px;
}

.fixBoxTop {
  position: fixed;
  top: 20px;
  z-index: 99999;
}

.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;

  li {
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
      // color: $white;
    }
  }
}

.heightZero {
  height: 0;
}

.actionbtn {
  margin-bottom: 20px;
}

.activeTab {
  background: rgb(195, 195, 196);
  color: rgb(20, 20, 20);

  a {
    color: rgb(5, 5, 5);
  }
}

.mr-btm {
  margin-bottom: 30px;
  margin-top: -40px;
}

a {
  text-decoration: none;
}

.mr-left {
  margin-left: 10px;
}

.dropdownlist {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0;
  padding: 8px 0;
  margin-top: -30px;

  li {
    list-style: none;
    padding: 8px 20px;
  }
}

.wishlistBtn {
  position: fixed;
  top: 0px;
  right: 370px;
  z-index: 999;
  transition: 0.3s all ease-in-out;
}

.dropBox {
  list-style: none;
  padding-left: 0;
  position: absolute;
  background: #fff;
  box-shadow: 0 5px 10px #efefef;

  li {
    padding: 10px 25px;
    border-bottom: 1px solid #dfdddd;
    cursor: pointer;

    &:last-of-type {
      border: none;
    }
  }
}

.relRow {
  position: relative;

  .dropBox {
    position: absolute;
    width: 150px;
    display: none;
  }

  &:hover {
    .dropBox {
      display: block;
    }
  }
}

.fixPop {
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  justify-content: center;
  z-index: 99999;

  .v-form {
    width: 500px;
  }

  .formBox {
    background: #fff;
    border-radius: 10px;
    width: 550px;
    padding: 30px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    font-size: 14px;

    .mdi-close {
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}

.rightmovePopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    width: 500px;
    height: 500px;
  }
}

.flexBox-space {
  display: flex;
  justify-content: space-between;
}

.iconSection {
  .v-icon {
    font-size: 30px;
  }
}

.dash {
  font-size: 20px;
  font-weight: 600;
  display: block;
}

.d-flex {
  .justify-space-between {
    span {
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}

.flexend {
  justify-content: flex-end;

  .v-btn {
    margin-left: 10px;
  }
}

.ulTop {
  list-style: none;
  padding-left: 0;
  width: 100%;
  padding-top: 10px;

  h5 {
    font-size: 16px;
    margin-bottom: 5px;

    @media only screen and (max-width: 1369px) {
      font-size: 13px;
    }
  }

  margin-bottom: 20px;

  li {
    box-shadow: 0 3px 5px #efefef;
    padding: 20px 40px;
    margin-right: 15px;
    width: 100%;

    span {
      font-size: 14px;
      display: block;
      padding: 5px 0;

      @media only screen and (max-width: 1369px) {
        font-size: 12px;
      }
    }
  }
}

.btnActReset {
  width: 90px;
}

.resetBtn {
  color: blue;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}

.filterBtn {
  position: fixed;
  right: 20px;
  border-radius: 50%;
  min-height: 60px;
  width: 50px;
  bottom: 50px;
}

.formInside {
  margin: 0;
  z-index: 999999;
  width: 150px !important;
  right: 200px;
  top: 15px;
  position: fixed;
}

.leftSec {
  margin: 0;
  z-index: 999999;
  position: fixed;
  top: 15px !important;
  width: 150px !important;

  &:nth-of-type(1) {
    left: 280px;
  }

  &:nth-of-type(2) {
    left: 420px;
  }
}

.favourite {
  color: red;
  width: 35px;
  height: 35px;
}

.favouriteO {
  width: 35px;
  height: 35px;
}

.addButton {
  // margin-left: 310px;
  min-width: 200px !important;
}

img {
  cursor: pointer;
}
</style>
